import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';



import { Typography } from '@mui/material';
import Container from 'components/Container';
import { Sidebar2023 } from 'views/SchoolProgram2023/components';

const PreChilderensDay2023 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const [config, setConfig] = useState({ base_image_archive_url: '' });
useEffect(() => {
  fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
    .then(response => response.json())
    .then(data => setConfig(data))
    .catch(error => console.error('Error fetching config:', error));
}, []);

const p1 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/1.webp`;
const p2 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/2.webp`;
const p3 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/3.webp`;
const p4 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/4.webp`;
const p5 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/5.webp`;
const p6 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/6.webp`;
const p7 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/7.webp`;
const p8 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/8.webp`;
const p9 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/9.webp`;
const p10 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/10.webp`;
const p11 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/11.webp`;
const p12 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/12.webp`;
const p13 = `${config.base_image_archive_url}/academic-year-2023-24/home/events-activities/school-programs/Prechildrensday/13.webp`;

    const photos = [
          {
                    src: p2,
                    source: p2,
                    rows: 1,
                    cols: 1,
                },
                {
                    src: p1,
                    source: p1,
                    rows: 1,
                    cols: 1,
                },
                {
                    src: p3,
                    source: p3,
                    rows: 1,
                    cols: 1,
                },
      {
          src: p4,
          source: p4,
          rows: 1,
          cols: 1,
      },
      {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
    },
    {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
    },
    {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
    },
{
src: p8,
source: p8,
rows: 1,
cols: 1,
},
{
    src: p9,
    source: p9,
    rows: 1,
    cols: 1,
},
{
    src: p10,
    source: p10,
    rows: 1,
    cols: 1,
},
{
    src: p11,
    source: p11,
    rows: 1,
    cols: 1,
},
{
src: p12,
source: p12,
rows: 1,
cols: 1,
},
{
    src: p13,
    source: p13,
    rows: 1,
    cols: 1,
},

     
      
      
    
     
    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                CHILDREN’S DAY CELEBRATION 	
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Class: Pre-Primary   Date: 1O November 2023
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                National Public School Yeshwanthpur celebrated Children's Day for the students of Pre-Primary on Friday, 10 November 2023 with unparalleled enthusiasm, creating a memorable experience for the young hearts. This day was specially dedicated for the students marking the highlight of celebrating the school’s decennial year of celebrations.   
                                    <br></br>
                                    The day unfolded with a mesmerizing blend of teachers' performances, fun games, lively dances with spectacular laser lighting and the unveiling of many surprises which was much to the delight of one and all.    
                                    <br></br>
                                    The students were greeted pleasantly by Principal ma’am during the school assembly, who announced the first of many surprises. To mark the celebration of 10 glorious years of our school, memorabilia in the form of a badge was gifted to every student which was the highlight of the day. 
                                    <br></br>
                                    The festivities kicked off with our dedicated teachers taking center stage, enchanting the students with a melody that resonated with joy and a heartwarming dance performance that was much to the excitement of all. Their passion and creativity were on full display, setting the tone for a day filled with laughter and celebration.
                                    <br></br>
                                    The festivities continued with an array of fun games that engaged the children in laughter and friendly competition. From classic bowling pin games to musical chairs, from innovative one-minute games to spirited floor games, every game brought smiles to the faces of our energetic students. The joyous spirit of camaraderie filled the air as students and teachers joined in the revelry
                                    <br></br>
                                    The rhythm of celebration continued with lively dance performances, featuring students showcasing their talents. A dazzling laser show illuminated the dance floor and the colorful attire and vibrant choreography of students added a touch of magic to the event, making it a visual treat for everyone present.
                                    <br></br>
                                    The Children's Day celebration at National Public School Yeshwanthpur was not just an event; it was a symphony of joy and creativity that resonated throughout the school. The dedication of our teachers, the infectious laughter of our students, and the vibrant performances made this day truly special. As we bid farewell to this extraordinary celebration, we carry with us the cherished memories of a day filled with love, laughter, and the pure delight of being a child.  
                                    <br></br>
                                </Typography>
                            </Box>
                            <Typography
                                variant={'h6'}
                                color={'primary'}
                                align={'center'}
                                fontWeight={'normal'}
                                fontFamily={' normal'}
                            ><br />
                                Shine on, little stars by embracing the magic in your uniqueness.
                                Happy Children’s Day.


                                <br />
                            </Typography>

                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 400 : 200}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                            <Sidebar2023/>
                        </Box> 
                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default PreChilderensDay2023;